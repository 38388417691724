<template>
  <div>
    <div class="pd_swiper">
      <van-swipe
        class="my-swipe pointsDetails"
        :autoplay="3000"
        indicator-color="white"
      >
        <van-swipe-item>
          <img
            class="headImg"
            src="https://img0.baidu.com/it/u=2869316406,2680107976&fm=26&fmt=auto&gp=0.jpg"
          />
        </van-swipe-item>
        <van-swipe-item>
          <img
            class="headImg"
            src="https://img0.baidu.com/it/u=2869316406,2680107976&fm=26&fmt=auto&gp=0.jpg"
          />
        </van-swipe-item>
      </van-swipe>
      <div class="cur">1/5</div>
    </div>
    <div class="pd_point"><span>699</span> 积分 <span>+10</span>元邮费</div>
    <div class="pd_title">
      <h1>中途清锁色修复膏30g 消炎褪肿 术中洗色修护</h1>
      <div class="pd_sub">已兑换155</div>
    </div>

    <div class="pd_details">
      <h1>详情介绍</h1>
      <div>
        <img
          src="https://img0.baidu.com/it/u=3607495788,154615704&fm=26&fmt=auto&gp=0.jpg"
        />
      </div>
    </div>
    <div class="pd_get pd_get_no-point">
      立即兑换/积分不足
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
export default {
  components: {
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.pointsDetails {
  width: rem(750);
  height: rem(750);
  .headImg {
    width: 100%;
    height: 100%;
  }
}

.pd_swiper {
  position: relative;
  .cur {
    width: rem(60);
    height: rem(32);
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: rem(16);
    font-size: rem(24);
    line-height: rem(32);
    /* identical to box height */

    bottom: rem(30);
    right: rem(30);
    text-align: center;
    color: #f9f9f9;
  }
}

.pd_point {
  color: #ffffff;
  font-size: rem(24);
  font-weight: bold;
  width: rem(750);
  box-sizing: border-box;
  height: rem(90);
  line-height: rem(90);
  background: url("~@image/points/details_bg.png") no-repeat;
  background-size: rem(750) rem(90);
  padding: 0 rem(30);
  span {
    font-size: rem(42);
  }
}

.pd_title {
  width: rem(690);
  min-height: rem(120);
  border-radius: rem(16);
  background: #fff;
  margin: rem(24) auto;
  padding: rem(16);
  box-sizing: border-box;
  h1 {
    font-size: rem(30);
    @include ell2;
    color: #222222;
    font-weight: bold;
  }
  .pd_sub {
    color: #989898;
    font-size: rem(26);
  }
}

.pd_details {
  width: rem(690);
  border-radius: rem(16);
  background: #fff;
  padding: rem(16);
  margin: 0 auto rem(30);
  box-sizing: border-box;
  h1 {
    color: #222222;
    font-size: rem(28);
    color: #222222;
    margin-bottom: rem(12);
  }
  div {
    img {
      width: 100%;
    }
  }
}

.pd_get {
  background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
  border-radius: rem(44);
  width: rem(690);
  height: rem(88);
  line-height: rem(88);
  margin: 0 auto rem(20);
  text-align: center;
  font-size: rem(30);
  color: #ffffff;
  font-weight: bold;
}
.pd_get_no-point {
  background: #c1c1c1;
}
</style>
